import React, { useState } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Button from "@material-ui/core/Button";

const ElectricityUsageGraph = ({ electricityData }) => {
  const [viewMode, setViewMode] = useState("used_vs_paid"); // Default mode

  if (!electricityData || electricityData.length === 0) {
    return (
      <div className="w-full mb-20 pb-20 text-center">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">
          Electricity Usage & Cost Comparison
        </h2>
        <p className="text-gray-500 text-lg">No electricity usage data available at the moment.</p>
      </div>
    );
  }

  // Compute actual monthly cost
  const processedData = electricityData.map((item, index, array) => {
    const previousUsage = index === 0 ? 0 : array[index - 1].usageSum;
    const previousCost = index === 0 ? 0 : array[index - 1].monetaryUsageSum || 0; // Ensure previousCost exists

    return {
      ...item,
      monthlyUsage: item.usageSum - previousUsage, // Monthly usage = current - previous month
      monthlyCost: Math.abs(item.monetaryUsageSum - previousCost), // Ensure cost is positive
    };
  });

  const formatMonth = (month) => {
    const date = new Date(month);
    return date.toLocaleString("en-US", { month: "short", year: "numeric" });
  };

  const getTitle = () => {
    return viewMode === "used_vs_paid"
      ? "Electricity Usage vs Paid Comparison"
      : "Monthly Electricity Cost (£)";
  };

  return (
    <div className="w-full mb-20 pb-20" style={{ minHeight: "250px" }}>
      <h2 className="text-lg sm:text-xl font-semibold text-center mb-4">
        {getTitle()}
      </h2>

      {/* Toggle Buttons */}
      <div className="flex justify-center gap-4 mb-4">
        <Button
          variant={viewMode === "used_vs_paid" ? "contained" : "outlined"}
          color="primary"
          onClick={() => setViewMode("used_vs_paid")}
        >
          kWh Used vs Paid
        </Button>
        <Button
          variant={viewMode === "monthly_cost" ? "contained" : "outlined"}
          color="secondary"
          onClick={() => setViewMode("monthly_cost")}
        >
          Monthly Cost (£)
        </Button>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        {/* Default View: kWh Used vs. Paid (Line Chart) */}
        {viewMode === "used_vs_paid" && (
          <LineChart data={processedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" tickFormatter={formatMonth} />
            <YAxis label={{ value: "kWh", angle: -90, position: "outsideLeft", dx: -20, fontSize: 14 }} />
            <Tooltip labelFormatter={(label) => formatMonth(label)} formatter={(value, name) => [value, name]} />
            <Legend />

            <Line type="monotone" dataKey="usageSum" stroke="#007bff" strokeWidth={2} name="Electricity Used (kWh)" />
            <Line type="monotone" dataKey="estimateSum" stroke="#ff7300" strokeWidth={2} strokeDasharray="5 5" name="Electricity Paid (kWh)" />
          </LineChart>
        )}

        {/* Monthly Cost View (Bar Chart) */}
        {viewMode === "monthly_cost" && (
          <BarChart data={processedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" tickFormatter={formatMonth} />
            
            {/* Left Y-axis for Cost (£) */}
            <YAxis
              tickFormatter={(value) => `£${value.toFixed(0)}`} // Format currency
              domain={[0, "auto"]} // Ensures no negative values
              label={{
                value: "Cost (£)", 
                angle: -90, 
                position: "outsideLeft", 
                dx: -20, // Aligns with left side
                fontSize: 14
              }}
            />

            <Tooltip labelFormatter={(label) => formatMonth(label)} formatter={(value) => `£${value.toFixed(2)}`} />
            <Legend />

            {/* Bar for Monthly Cost */}
            <Bar dataKey="monthlyCost" fill="#ff7300" name="Monthly Cost (£)" />
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default ElectricityUsageGraph;
