import React, { useState, useCallback, useContext, useEffect } from "react";
import WarningIcon from "@material-ui/icons/Warning";
import AppTitle from "../../components/appTitle/AppTitle";
import PageWrapper from "../../components/PageWrapper";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import Frame from "../../components/frame/Frame";
import Frames from "../../components/frames/Frames";
import OptimizedTable from "../../components/optimizedTable/OptimizedTable";
import { getPayments as getTenancyPayments } from "../../services/tenantsApiV2"

export default ({ location, tenancyId }) => {
  const context = useContext(GlobalStateContext);
  const [payments, setPayments] = useState([]);
  const [loadingError, setLoadingError] = useState(null);
  const [loading, setLoading] = useState(true);

  const getPayments = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getTenancyPayments(tenancyId);
      if (data && data.value) {
        setPayments(data);
        console.log(data);
      }
    } catch (e) {
      console.error("Error fetching payments:", e);
    }
    setLoading(false);
  }, [tenancyId]);

  useEffect(() => {
      getPayments();
  }, [tenancyId]);

  function formatISODateToMonthYear(dateString) {
    if (!dateString || typeof dateString !== "string") return "Invalid date";
    try {
      const [datePart] = dateString.split("T");
      const date = new Date(datePart);
      return isNaN(date.getTime())
        ? "Invalid date format"
        : date.toLocaleDateString("en-GB", { month: "short", year: "numeric" });
    } catch (error) {
      return "Invalid date format";
    }
  }

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={loading}
      showLoadingLogo={true}
      showLoadingText="Getting your live data"
    >
        <div>
          <AppTitle
            showBackHome={true}
            title={`Your payments`}
          />
          {(payments && payments.length > 0 ? (
            <Frames>
              <Frame>
                <OptimizedTable
                //   rows={payments.map((payment) => [
                //     [
                //       {
                //         type: "title",
                //         value: `${payment.value1} ${payment.value2}`,
                //       },
                //       {
                //         type: "title",
                //         value: <FaCheckCircle size={24} color="green" />,
                //       },
                //     ],
                //   ])}
                />
              </Frame>
            </Frames>
            
          ) : <div>Nothing to see here...check back later!</div> )}
        </div>
    </PageWrapper>
  );
};
