import React, { useEffect, useState, useCallback } from "react"
import { Link, navigate } from "gatsby"

import {
  getHousehold as apiGetHousehold,
  getTenancyMeter as apiGetTenancyMeter,
  uploadTenancyMeterReading as apiUploadTenancyMeterReading,
  getTenancyMeterReading as apiGetTenancyMeterReading,
} from "../../services/tenantsApiV2"

import Form from "../../components/Form"
import InformationCards from "../../components/informationCards/InformationCards"
import DialogModal from "../../components/dialogModal"
import LoadingIcon from "../../components/loadingIcon/LoadingIcon"

import * as submitMeterReadingStyles from "./submitMeterReading.module.css"

const isLessThanPreviousReadings = (pastReadings, meterReadingPayload) => {
  if (!pastReadings || pastReadings.length === 0) {
    return false
  }
  const latestReading = pastReadings[0]
  const latestReadingReading1 = parseInt(latestReading.reading1)
  const meterReadingPayloadReading1 = parseInt(
    convertReading(meterReadingPayload.reading)
  )

  if (meterReadingPayloadReading1 < latestReadingReading1) {
    return true
  }

  if (
    meterReadingPayload.reading2
  ) {
    const latestReadingReading2 = parseInt(latestReading.reading2)
    const meterReadingPayloadReading2 = parseInt(
      convertReading(meterReadingPayload.reading2)
    )

    if (meterReadingPayloadReading2 < latestReadingReading2) {
      return true
    }
  }

  return false
}

const convertReading = meterReading => {
  if (!meterReading) {
    return ""
  }
  let reading = [...meterReading]
  if (reading && reading.filter(_ => _).length > 0) {
    //reading.splice(reading.length - 2, 0, ".")
    return reading
      .map(_ => _ || 0)
      .join("")
      .replace(/^0+/, "")
      .trim()
  }

  return ""
}

let submitElectricMeterReadingFormOptions = {
  fields: [
    {
      name: "reading",
      placeholder: "Reading",
      type: "numberInput",
      required: true,
    },
    {
      name: "elecPhoto",
      type: "selectPhoto",
      placeholder: "Upload photo",
      required: true,
    },
  ],
  submitText: "Confirm meter reading",
  submitButtonStyle: {
    marginRight: 10,
  },
}

let submitGasMeterReadingFormOptions = {
  fields: [
    {
      name: "reading",
      placeholder: "Reading",
      type: "numberInput",
      required: true,
    },
    {
      name: "gasPhoto",
      type: "selectPhoto",
      placeholder: "Upload photo",
      required: true,
    },
  ],
  submitText: "Confirm meter reading",
  submitButtonStyle: {
    marginRight: 10,
  },
}

let submitEconomy7MeterReadingFormOptions = {
  fields: [
    {
      name: "reading",
      placeholder: "Reading 1",
      type: "numberInput",

      required: true,
    },
    {
      name: "reading2",
      placeholder: "Reading 2",
      type: "numberInput",

      required: true,
    },
    {
      name: "elecPhoto",
      type: "selectPhoto",
      placeholder: "Upload photo",
      helperText: "Ensure the photo shows the serial number",
      required: true,
    },
    {
      name: "elecPhoto2",
      type: "selectPhoto",
      placeholder: "Upload photo",
      helperText: "Ensure the photo shows the serial number",
    },
  ],
  submitText: "Confirm meter reading",
  submitButtonStyle: {
    marginRight: 10,
  },
}

export default ({ key, tenancyId, meter, onSubmit }) => {
  const [uploadingMeterReading, setUploadingMeterReading] = useState(false)
  const [
    gettingMeterReadingEvidence,
    setGettingMeterReadingEvidence,
  ] = React.useState(false)
  const [
    submitMeterReadingFormOptions,
    setSubmitMeterReadingFormOptions,
  ] = useState(null)
  const [meterReadingEvidence, storeMeterReadingEvidence] = React.useState("")
  const [onlyShowPreviousReading, setOnlyShowPreviousReading] = useState(true)
  const [meterReadingPayload, storeMeterReadingPayload] = useState({
    reading: "",
    reading2: "",
  })
  const [
    showConfirmMeterReadingWithoutPhoto,
    setShowConfirmMeterReading,
  ] = useState(false)
  const [respsonseMessage, setResponseMessage] = useState("")

  const checkUploadMeterReading = payload => {
    storeMeterReadingPayload(payload)
    setShowConfirmMeterReading(true)
  }

  const uploadTenancyMeterReading = async payload => {
    try {
      setUploadingMeterReading(true)

      let reading1 = [...payload.reading]
      reading1 = convertReading(reading1)

      let reading2 = payload.reading2 ? [...payload.reading2] : null
      reading2 = convertReading(reading2)

      await apiUploadTenancyMeterReading(tenancyId, meter.id, {
        ...payload,
        type: meter.type,
        reading: reading1,
        reading2,
      })

      setResponseMessage(
        "Thanks! Your meter reading will appear on the portal in a couple of minutes, and we'll email you once it has been approved."
      )

      onSubmit()
      setShowConfirmMeterReading(false)
      storeMeterReadingPayload({
        reading: "",
        reading2: "",
        photo: null,
      })
    } catch (e) {
      console.log(e)
      setResponseMessage("We are not able to submit your meter reading at this moment, please try again later")
      setShowConfirmMeterReading(false)

    }
    setUploadingMeterReading(false)
  }

  const getTenancyMeterReading = async meterReadingId => {
    try {
      setGettingMeterReadingEvidence(true)
      const data = await apiGetTenancyMeterReading(meterReadingId)
      if (data && data.attachments && data.attachments.length > 0) {
        storeMeterReadingEvidence(data.attachments[0])
      }
    } catch (e) {
      console.log(e)
    }
    setGettingMeterReadingEvidence(false)
  }

  useEffect(() => {
    if (meter.type === "Economy 7") {
      setSubmitMeterReadingFormOptions(submitEconomy7MeterReadingFormOptions)
    } else if (meter.type === "Electric") {
      setSubmitMeterReadingFormOptions(submitElectricMeterReadingFormOptions)
    } else if (meter.type === "Gas") {
      setSubmitMeterReadingFormOptions(submitGasMeterReadingFormOptions)
    }
  }, [meter])

  return (
    <div key={key}>
      <div className={submitMeterReadingStyles.submitMeterReading}>
        {meter && meter.pastReadings && meter.pastReadings.length > 0 && (
          <div style={{ marginBottom: 20 }}>
            <InformationCards
              rows={meter.pastReadings
                .filter((pastReading, index) =>
                  onlyShowPreviousReading ? index === 0 : true
                )
                .map(pastReading => ({
                  id: pastReading.zohoEntityId,
                  title:
                    pastReading.reading1 +
                    (pastReading.reading2 ? `, ${pastReading.reading2}` : ""),
                  value: new Date(pastReading.createdAt).toLocaleDateString("en-GB"),
                  details: [
                    pastReading.supplier,
                    `Reading period: ${pastReading.readingPeriod}`,
                    `Status: ${pastReading.status}`,
                    pastReading.rejectReason &&
                    `Reject reason: ${pastReading.rejectReason}`,
                  ],
                  footerTitle:
                    pastReading.providedBy &&
                    `Provided by: ${pastReading.providedBy}`,
                  footerValue: pastReading.hasAttachments && "View evidence",
                  onFooterValueClick: () =>
                    getTenancyMeterReading(pastReading.zohoEntityId),
                }))}
            />
            {meter.pastReadings.length > 1 && (
              <div
                style={{ textDecorationLine: "underline", cursor: "pointer" }}
                onClick={() =>
                  setOnlyShowPreviousReading(!onlyShowPreviousReading)
                }
                onKeyPress={() =>
                  setOnlyShowPreviousReading(!onlyShowPreviousReading)
                }
                role="button"
                tabIndex="0"
              >
                {onlyShowPreviousReading
                  ? "View all readings"
                  : "Only show most recent reading"}
              </div>
            )}
          </div>
        )}
        <div className={submitMeterReadingStyles.stepNumber}>
          Enter new meter reading
        </div>
        {submitMeterReadingFormOptions && (
          <Form
            options={submitMeterReadingFormOptions}
            // buttonPosition="floatLeft"
            initialPayload={meterReadingPayload}
            onSubmit={payload => checkUploadMeterReading(payload)}
            submitting={uploadingMeterReading}
            reducedPadding={true}
            apiSuccessMessage={respsonseMessage}
          />
        )}
      </div>

      <DialogModal
        onClose={() => storeMeterReadingEvidence(null)}
        hideCancelButton={true}
        showCloseIcon={true}
        open={gettingMeterReadingEvidence || meterReadingEvidence}
      >
        <div className={submitMeterReadingStyles.meterReadingEvidence}>
          {gettingMeterReadingEvidence && <LoadingIcon />}
          {!gettingMeterReadingEvidence && (
            <img
              alt="Meter reading evidence"
              src={`data:image/png;base64, ${meterReadingEvidence}`}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          )}
        </div>
      </DialogModal>

      {meterReadingPayload && meter && (
        <DialogModal
          open={showConfirmMeterReadingWithoutPhoto}
          onClose={() => setShowConfirmMeterReading(false)}
          title="Confirm meter reading"
          onSubmit={() => uploadTenancyMeterReading(meterReadingPayload)}
          submitText="Confirm"
          submitting={uploadingMeterReading}
        >
          <div style={{ padding: "0 24px 24px 24px" }}>
            <div style={{ marginBottom: 10 }}>
              You are submitting a meter reading with reading
              {meterReadingPayload.reading2 &&
                meterReadingPayload.reading2.length > 0
                ? "s"
                : ""}
              : {convertReading(meterReadingPayload.reading)}
              {meterReadingPayload.reading2 &&
                meterReadingPayload.reading2.length > 0
                ? `, ${convertReading(meterReadingPayload.reading2)}`
                : ""}
            </div>
            {isLessThanPreviousReadings(
              meter.pastReadings,
              meterReadingPayload
            ) && (
                <div style={{ marginBottom: 10 }}>
                  Your meter reading values are less than the last store reading,
                  are you sure this is correct?
                </div>
              )}
            {!meterReadingPayload.elecPhoto &&
              !meterReadingPayload.gasPhoto && (
                <div>Are you sure you don't want to upload a photo?</div>
              )}
          </div>
        </DialogModal>
      )}
    </div>
  )
}
