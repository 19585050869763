import React, { useContext, useCallback, useState } from "react"
import AppTitle from "../../components/appTitle/AppTitle"
import PageWrapper from "../../components/PageWrapper"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { formatCurrency } from "../../util/finance"
import ServicesUsageGraph from "../../components/servicesUsageGraph/ServicesUsageGraph"
import MaintenanceAlert from "./components/MaintenanceAlert";

import * as usageStyles from "./usage.module.css"

import UsageGraphs from "./components/phoenix-usage-charts/UsageGraph"
import {
  getTenancyUsage as apiGetTenancyUsage
} from "../../services/tenantsApiV2"

import Typography from "@material-ui/core/Typography"
import Frames from "../../components/frames/Frames"
import Frame from "../../components/frame/Frame"

import {
  Chart as ChartJS,
  CategoryScale, // For the "category" scale
  LinearScale, // For numerical values
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default ({ location, tenancyId }) => {
  const context = useContext(GlobalStateContext)

  const [loading, setLoading] = useState(true)
  const [contractServiceUsages, storeContractServiceUsages] = useState(null)
  const [bills, setBills] = useState()
  const [usageData, setUsageData] = useState(null);

  const [showCumulative, setShowCumulative] = useState(false);

  const getTenancyDetails = useCallback(async () => {
    try {
      setLoading(true); // Start loading state

      const data = await apiGetTenancyUsage(tenancyId); // Fetch the tenancy usage data
      console.log("API Response:", data);

      if (data.success) {
        const { energyChartResults, tenancy, contractServiceUsages, ...billData } = data.value;

        // Set usage data if energy chart results are available
        if (energyChartResults) {
          setUsageData(energyChartResults);
          console.log(energyChartResults)
        } else {
          //storeTenancy(tenancy);
          storeContractServiceUsages(contractServiceUsages);

          // Extract bills and update state
          setBills({
            ...billData,
            lastReconDate: billData.lastReconDate
              ? new Date(billData.lastReconDate).toLocaleDateString("en-GB")
              : null,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching tenancy details:", error); // Improved error logging
    } finally {
      setLoading(false); // Stop loading state
    }
  }, [tenancyId]);
  React.useEffect(() => {
    console.log(context.tenancy);
      getTenancyDetails()
    
    setLoading(false);
  }, [context.loggedIn])

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={loading}
      showLoadingLogo={true}
      showLoadingText="Getting your live data"
    >
      <div className={usageStyles.usage}>
        <AppTitle title={context.tenancy?.isPhoenixAccount ? "Your Energy Usage" : "Your account bills!"} color={"red"} />
        
        <div>
          {context.tenancy?.isPhoenixAccount &&
            
              <div style={{ paddingBottom: "5%" }}>
                {usageData && usageData.electric ? <UsageGraphs energyData={usageData} /> :
                  <p>No data available for Electric or Gas usage.</p>
                }

              </div>
            }
          {
            context.tenancy && !context.tenancy.unlimited && contractServiceUsages != null &&
            <div>
              <Frame title="Bills Overview">
                <div className={usageStyles.usagePanels}>
                  <ServicesUsageGraph
                    tenancyId={tenancyId}
                    type="Energy"
                    data={contractServiceUsages}
                    hideGraph
                  />
                  <ServicesUsageGraph
                    tenancyId={tenancyId}
                    type="Electric"
                    data={contractServiceUsages}
                    hideGraph
                  />
                  <ServicesUsageGraph
                    tenancyId={tenancyId}
                    type="Gas"
                    data={contractServiceUsages}
                    hideGraph
                  />
                </div>
                <div className={usageStyles.usagePanels}>
                  <ServicesUsageGraph
                    tenancyId={tenancyId}
                    type="Water"
                    data={contractServiceUsages}
                    hideGraph
                  />
                  <ServicesUsageGraph
                    tenancyId={tenancyId}
                    type="Broadband"
                    data={contractServiceUsages}
                    hideGraph
                  />
                  <ServicesUsageGraph
                    tenancyId={tenancyId}
                    type="TV Licence"
                    data={contractServiceUsages}
                    hideGraph
                  />
                </div>
              </Frame>
              {bills != null && bills.totalElectricBill != null && (bills.totalGasBill != null) && (bills.totalWaterBill != null) &&
                <Frames>
                  <Frame title="Household Bill Summary" description={bills.lastReconDate ? "Latest Update :   " + bills.lastReconDate : ""}>
                    <div style={{ display: "flex", flexDirection: "column", justifyItems: "flex-start", flexWrap: "wrap" }}>
                      <Typography style={{ margin: "5px 0px" }}>This shows the sum of your household's total bills to date, per service, since the start of your contract</Typography>
                      <div style={{ margin: "25px 0px 5px 0px" }}>
                        <Typography className="h6"  >Total Electric Bill :  {formatCurrency(bills.totalElectricBill)}</Typography>
                      </div>
                      <div style={{ margin: "5px 0px" }}>
                        <Typography className="h6"  >Total Gas Bill :  {formatCurrency(bills.totalGasBill)}</Typography>
                      </div>
                      <div style={{ margin: "5px 0px" }}>
                        <Typography className="h6"  >Total Water Bill :  {formatCurrency(bills.totalWaterBill)}</Typography>
                      </div>
                      <div style={{ margin: "5px 0px" }}>
                        <Typography className="h6"  >Total Broadband Bill :  {formatCurrency(bills.totalBroadbandBill)}</Typography>
                      </div>
                      {bills.totalCanopyBill != null &&
                        <div style={{ margin: "5px 0px" }}>
                          <Typography className="h6"  >Total Canopy Bill :   {formatCurrency(bills.totalCanopyBill)}</Typography>
                        </div>
                      }
                      {bills.totalSky != null &&
                        <div style={{ margin: "5px 0px" }}>
                          <Typography className="h6"  >Total Sky Bill :  {formatCurrency(bills.totalSky)}</Typography>
                        </div>
                      }
                      {bills.totalSubscriptionFees != null &&
                        <div style={{ margin: "5px 0px" }}>
                          <Typography className="h6"  >Total Subscription Fees :  {formatCurrency(bills.totalSubscriptionFees)}</Typography>
                        </div>
                      }
                      {bills.totalTVLBill != null &&
                        <div style={{ margin: "5px 0px" }}>
                          <Typography className="h6"  >Total TVL Bill :  {formatCurrency(bills.totalTVLBill)}</Typography>
                        </div>
                      }
                      {bills.totalOtherBills != null &&
                        <div style={{ margin: "5px 0px" }}>
                          <Typography className="h6"  >Total Other Bills :  {formatCurrency(bills.totalOtherBills)}</Typography>
                        </div>
                      }
                    </div>
                  </Frame>
                </Frames>
              }
            </div>
          }
        </div>
      </div>
    </PageWrapper>
  )
}
