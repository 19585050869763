import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Button from "@material-ui/core/Button";

const GasUsageGraph = ({ gasData }) => {
  const [viewMode, setViewMode] = useState("used_vs_paid"); // Default mode

  // Handle empty data scenario
  if (!gasData || gasData.length === 0) {
    return (
      <div className="w-full mb-20 pb-20 text-center">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">
          Gas Usage vs Paid Comparison
        </h2>
        <p className="text-gray-500 text-lg">No gas usage data available at the moment.</p>
      </div>
    );
  }

  // Compute cumulative usage over time
  const processedData = gasData.map((item, index, array) => {
    const cumulativeUsage = index === 0 ? item.usageSum : (array[index - 1].cumulativeUsage || array[index - 1].usageSum) + item.usageSum;
    return { ...item, cumulativeUsage };
  });

  const formatMonth = (month) => {
    const date = new Date(month);
    return date.toLocaleString("en-GB", { month: "short", year: "numeric" }); // Formats as "Jan 2024"
  };

  return (
    <div className="w-full mb-20 pb-20" style={{ minHeight: "250px" }}>
      <h2 className="text-lg sm:text-xl font-semibold text-center mb-4">
        Gas Usage vs Paid Comparison
      </h2>

      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={processedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" tickFormatter={formatMonth} />
          <YAxis
            label={{
              value: viewMode === "cumulative_usage" ? "Cumulative kWh" : "kWh",
              angle: -90,
              position: "outsideLeft",
              dx: -20,
              fontSize: 18,
            }}
          />
          <Tooltip 
            labelFormatter={(label) => formatMonth(label)} // ✅ Fix tooltip format
            formatter={(value, name) => [value, name]} 
          />
          <Legend />

          {/* Default View: kWh Used vs. Paid */}
          {viewMode === "used_vs_paid" && (
            <>
              <Line type="monotone" dataKey="usageSum" stroke="#ff0000" strokeWidth={2} name="Gas Used (kWh)" />
              <Line type="monotone" dataKey="estimateSum" stroke="#800080" strokeWidth={2} strokeDasharray="5 5" name="Gas Paid (kWh)" />
            </>
          )}

          {/* View: Cumulative Usage Over Time */}
          {viewMode === "cumulative_usage" && (
            <Line type="monotone" dataKey="cumulativeUsage" stroke="#00cc99" strokeWidth={2} name="Cumulative Gas Used (kWh)" />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GasUsageGraph;
