import React from "react";
import ElectricityUsageGraph from "./components/ElectricityUsageGraph";
import GasUsageGraph from "./components/GasUsageGraph";

const UsageGraphs = ({ energyData }) => {
  return (
    <div className="w-full sm:w-4/5 max-w-screen-lg mx-auto flex flex-col items-center space-y-24 px-4 pb-24">
      <ElectricityUsageGraph electricityData={energyData.electric} />
      <div style={{height: "20px"}}></div>
      <GasUsageGraph gasData={energyData.gas} />
    </div>
  );
};

export default UsageGraphs;
