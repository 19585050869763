import React, { useContext, useCallback } from "react"
import { DateTime } from "luxon"

import {
  getMyTenancy as apiGetMyTenancy,
  updateUserTenancy as apiUpdateUserTenancy,
  updateAdditionalServices as apiUpdateAdditionalServices,
  getTenancyTenants as apiGetTenancyTenants,
  getTenancyContractServices as apiGetTenancyContractServices,
  sendSignUpReminder as apiSendSignUpReminder,
  getAgencies as apiGetAgencies,
} from "../../services/tenantsApiV2"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import PageWrapper from "../../components/PageWrapper"
import AppTitle from "../../components/appTitle/AppTitle"
import Form from "../../components/Form"
import OptimizedTable from "../../components/optimizedTable/OptimizedTable"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import Frame from "../../components/frame/Frame"
import Frames from "../../components/frames/Frames"
import { PSROptions } from "../../constants"
import validator from "validator"
import {
  validateEmail,
} from "../../services/errorValidation"

import * as tenancyInformationStyles from "./tenancyInformation.module.css"


export default ({ location, tenancyId }) => {

  
  
  return <></>
}
