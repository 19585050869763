import React, { useContext, useCallback } from "react"

import {
  getMyTenancy as apiGetMyTenancy,
  updateUserContact as apiUpdateUserContact,
  updatePassword as apiUpdatePassword,
  getContact as apiGetContact,
} from "../../services/tenantsApiV2"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import PageWrapper from "../../components/PageWrapper"
import Form from "../../components/Form"
import AppTitle from "../../components/appTitle/AppTitle"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import Frame from "../../components/frame/Frame"
import TextInput from "../../components/textInput/TextInput"

import * as editProfileStyles from "./editProfile.module.css"

import validator from "validator"

import {
  validateEmail,
} from "../../services/errorValidation"
import { DateTime } from "luxon"
import { PSROptions } from "../../constants"

import {
  updateUserTenancy as apiUpdateUserTenancy,
} from "../../services/tenantsApiV2"

const editPasswordOptions = {
  fields: [
    {
      name: "oldPassword",
      placeholder: "Old password",
      type: "password",
      required: true,
    },
    {
      name: "newPassword",
      placeholder: "New password",
      type: "password",
      required: true,
      validate: value => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value),
    },
    {
      name: "repeatPassword",
      placeholder: "Repeat password",
      type: "password",
      required: true,
      validate: (value, formPayload) => value === formPayload["newPassword"],
    },
  ],
  submitText: "Update password",
}

export default ({ location, tenancyId }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const [gettingTenancies, setGettingTenancies] = React.useState(true)
  const [tenancy, storeTenancy] = React.useState(null)
  const [contact, storeContact] = React.useState(null)
  const [
    updatingContactInformation,
    setUpdatingContactInformation,
  ] = React.useState(false)
  const [
    updateContactInformationError,
    setUpdateContactInformationError,
  ] = React.useState("")
  const [
    updateContactInformationSuccess,
    setUpdateContactInformationSuccess,
  ] = React.useState("")
  const [updatingPassword, setUpdatingPassword] = React.useState(false)
  const [updatePasswordError, setUpdatePasswordError] = React.useState("")
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = React.useState("")
  const [getMyTenancyError, setGetMyTenancyError] = React.useState(null)
  const [updatingTenancyInformation, setUpdatingTenancyInformation ] = React.useState(false)
  const [updateTenancyInformationError, setUpdateTenancyInformationError] = React.useState("")
  const [updateTenancyInformationSuccess, setUpdateTenancyInformationSuccess] = React.useState("")
  

  const getMe = useCallback(async () => {
    try {
      const data = await apiGetContact(tenancyId)
      if (data.value) {
        storeContact({
          ...data.value,
          forwardingAddress: {
            ...data.value.forwardingAddress,
            formattedAddress: Object.keys(data.value.forwardingAddress)
              .filter(key => data.value.forwardingAddress[key])
              .map(key => data.value.forwardingAddress[key])
              .join(", "),
          },
        })
      }
    } catch (e) {
      console.log(e)
      setGetMyTenancyError(e.message)
    }
  }, [tenancyId])
  
  function validateName(name) {
    return (
      name.trim() !== "" &&
      validator.isAlpha(name, "en-GB", {
        ignore: " -",
      })
    )
  }

  const updateContactInformation = async payload => {
    console.log(payload)
    if (!payload || !payload.email) {
      return setUpdateContactInformationError("Please complete all fields")
    }

    setUpdateContactInformationError("")

    try {
      setUpdatingContactInformation(true)
      const data = await apiUpdateUserContact(tenancyId, {
        ...context.tenantUser,
        ...payload,
      })

      if (data.success) {
        setUpdateContactInformationSuccess("Profile updated")
        if (data.value) {
          dispatch({
            type: "storeTenantUser",
            tenantUser: data.value,
          })
        }
      } else if (data.errors) {
        setUpdateContactInformationError(data.errors[0].reason)
      } else {
        setUpdateContactInformationError(
          "An error occurred trying to update profile"
        )
      }
      setUpdatingContactInformation(false)
      getMe()
    } catch (e) {
      setUpdatingContactInformation(false)
      setUpdateContactInformationError(e.message)
    }
  }

  const updatePassword = async payload => {
    if (
      !payload ||
      !payload.oldPassword ||
      !payload.newPassword ||
      !payload.repeatPassword
    ) {
      return setUpdatePasswordError("Please complete all fields")
    }

    if (payload.newPassword !== payload.repeatPassword) {
      return setUpdatePasswordError("Passwords do not match")
    }

    setUpdatePasswordError("")
    try {
      setUpdatingPassword(true)
      const data = await apiUpdatePassword(payload)
      if (data.success) {
        console.log(data)
        setUpdatePasswordSuccess("Password updated")
      } else if (data.errors) {
        setUpdatePasswordError(data.errors[0].reason)
      } else {
        setUpdatePasswordError("An error occurred trying to update profile")
      }
      setUpdatingPassword(false)
    } catch (e) {
      setUpdatingPassword(false)
      setUpdatePasswordError(e.message)
    }
  }

  const getMyTenancy = useCallback(async () => {
    try {
      setGettingTenancies(true)
      const data = await apiGetMyTenancy(tenancyId)
      if (data.value) {
        storeTenancy(data.value)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancies(false)
  }, [tenancyId])

  const updateTenancyInformation = async payload => {
    if (!payload.isVulnerablePerson){
      payload.psrCategory = [];
      payload.psrAuthorisedContactName = '';
      payload.psrAuthorisedContactEmail = '';
      payload.psrAuthorisedContactDateOfBirth = '';
    }
    if (payload.isVulnerablePerson && payload.psrCategory.length < 1) {
      return setUpdateTenancyInformationError("Please select the PSR reason")
    }
    if (payload.psrAuthorisedContactName ||  payload.psrAuthorisedContactEmail || payload.psrAuthorisedContactDateOfBirth){
      if (!payload.psrAuthorisedContactName ||  !payload.psrAuthorisedContactEmail || !payload.psrAuthorisedContactDateOfBirth){
        return setUpdateTenancyInformationError("If an authorised contact is specified, name, email and date of birth are all required.")
      }
    }
    if (payload.psrAuthorisedContactName && !validateName(payload.psrAuthorisedContactName)){
      return setUpdateTenancyInformationError("Authorised contact name can only contain letters and '-' characters.")
    }

    if (payload.psrAuthorisedContactName && payload.psrAuthorisedContactName.length > 200){
      return setUpdateTenancyInformationError("The authorised contact name can only be up to 200 characters in length")
    }
    if (payload.psrAuthorisedContactEmail && payload.psrAuthorisedContactEmail.length > 320){
      return setUpdateTenancyInformationError("The authorised contact email can only be up to 320 characters in length")
    }
    if (payload.psrAuthorisedContactEmail && !validateEmail(payload.psrAuthorisedContactEmail)){
      return setUpdateTenancyInformationError("The authorised contact email is invalid")
    }

    if (payload.psrAuthorisedContactDateOfBirth){
      const authContactDate = new Date(payload.psrAuthorisedContactDateOfBirth);

      if (authContactDate > DateTime.local().plus({ years: -18 })){
        return setUpdateTenancyInformationError("The authorised contact must be at least 18 years old")
      }
    }

    payload["isPsrUpdated"] = payload.isVulnerablePerson === context.tenancy.isVulnerablePerson 
    && (payload.psrCategory === context.tenancy.psrCategory || payload.psrCategory === null) 
    && (payload.psrAuthorisedContactName === context.tenancy.psrAuthorisedContactName || payload.psrAuthorisedContactName === null) 
    && (payload.psrAuthorisedContactEmail === context.tenancy.psrAuthorisedContactEmail || payload.psrAuthorisedContactEmail === null)
    && (payload.psrAuthorisedContactDateOfBirth === context.tenancy.psrAuthorisedContactDateOfBirth || payload.psrAuthorisedContactDateOfBirth === null) ? false : true

    const isTenancyAgreementUploaded = payload.tenancyAgreement ? true : false

    const isLoaUploaded = payload.loa ? true : false

    if (!payload.isAgencyUpdated && !payload.isResiTypeUpdated && !payload.isBroadbandStartDateUpdated && !payload.isPsrUpdated && !isTenancyAgreementUploaded && !isLoaUploaded) {
      return setUpdateTenancyInformationError("No data has been updated")
    }

    setUpdateTenancyInformationError("")

    try {
      setUpdatingTenancyInformation(true)

      if (payload.psrAuthorisedContactDateOfBirth){
        const authContactDate = new Date(payload.psrAuthorisedContactDateOfBirth);

        payload.psrAuthorisedContactDateOfBirth = authContactDate.toISOString();
      }

      const data = await apiUpdateUserTenancy(tenancyId, {
        ...context.tenantUser,
        ...payload
      })

      if (data.success) {
        setUpdateTenancyInformationSuccess("PSR successfully updated.")
      } else if (data.errors) {
        setUpdateTenancyInformationError(data.errors[0].reason)
      } else {
        setUpdateTenancyInformationError(
          "An error occurred."
        )
      }
      setUpdatingTenancyInformation(false)
      getMyTenancy(tenancyId)
    } catch (e) {
      setUpdatingTenancyInformation(false)
      setUpdateTenancyInformationError(e.message)
    }
  }

  const renderForm = () => {
    if (context.tenancy) {
      // These fields are just shown to the user, they can't be updated
      const unmodifiableTenancyFormOptions = {
        fields: [
          
        ],
        // submitText: context.tenancy.isLeadTenant && "Save",
      }

      const updateTenancyFormOptions = {
        fields: [
          {
            name: "isVulnerablePerson",
            label: "Are you registered with the Priority Services Register? Please tick here and select the reason from the list below?",
            type: "checkbox",
          },
          {
            name: "psrCategory",
            type: 'dropdown',
            placeholder: 'PSR Category',
            options: PSROptions.map(psrOption => ({ label: psrOption, value: psrOption })),
            showIfTrue: ["isVulnerablePerson"],
            multiSelect: true,
            label: 'PSR Category'
          },
          {
            name: "psrAuthorisedContactName",
            placeholder: "PSR Authorised Contact Name",
            type: "text",
            showIfTrue: ["isVulnerablePerson"],
          },
          {
            name: "psrAuthorisedContactEmail",
            placeholder: "PSR Authorised Contact Email",
            type: "email",
            showIfTrue: ["isVulnerablePerson"],
          },
          {
            name: "psrAuthorisedContactDateOfBirth",
            placeholder: "PSR Authorised Contact Date of Birth",
            type: "date",
            clearable: true,
            showIfTrue: ["isVulnerablePerson"],
          },
        ],
        submitText: "Save",
      }

      const payload = context.tenancy
      payload["package"] = context.tenancy?.unlimited ? "Unlimited" : "PAYG"
      return (
        <Frame>
          <Form
            options={unmodifiableTenancyFormOptions}
            initialPayload={payload}
            showCallUs={false}
            reducedPadding={true}
            apiErrorMessage={updateTenancyInformationError}
            apiSuccessMessage={updateTenancyInformationSuccess}
          />
          <Form
            options={updateTenancyFormOptions}
            initialPayload={payload}
            showCallUs={false}
            reducedPadding={true}
            submitting={updatingTenancyInformation}
            apiErrorMessage={updateTenancyInformationError}
            apiSuccessMessage={updateTenancyInformationSuccess}
            onSubmit={payload => updateTenancyInformation(payload)}
          />
        </Frame>
      )
    }
    return null
  }


  React.useEffect(() => {
    getMyTenancy()
    getMe()
  }, [context.loggedIn, getMe, getMyTenancy])

  console.log("tenancy", tenancy)

  return (
    <PageWrapper
      location={location}
      showLoadingIndicator={
        gettingTenancies || !context.tenantUser || !context.tenantUser.email
      }
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      tenancy={tenancy}
    >
      <div className={editProfileStyles.editProfile}>
        <AppTitle title="Account Details" />
        {getMyTenancyError &&
          getMyTenancyError === "Please verify your account to continue" && (
            <VerifyAccountToContinue />
          )}
        {contact && (
          <div>
            <Frame>
              <div class="form-module--formWrapper--mlHXz">               
                  <TextInput
                    placeholder="First Name"
                    type="text"
                    autoComplete="off"
                    value={contact.firstName}
                    disabled={true}
                  />
                  <TextInput
                    placeholder="Last Name"
                    type="text"
                    autoComplete="off"
                    value={contact.lastName}
                    disabled={true}
                  />
                  <TextInput
                    placeholder="Email Address"
                    type="text"
                    autoComplete="off"
                    value={contact.email}
                    disabled={true}
                  />     
                  <TextInput
                    placeholder="Mobile Phone Number"
                    type="text"
                    autoComplete="off"
                    value={contact.phoneNumber}
                    disabled={true}
                  />
                  <TextInput
                    placeholder="Address"
                    type="text"
                    autoComplete="off"
                    value={contact.forwardingAddress.formattedAddress}
                    disabled={true}
                  />  
                  <div className={editProfileStyles.callUs}>
                    Is this information incorrect? Get in contact with us on{" "}
                    <a href="tel:0333 358 3377">0333 358 3377</a>
                  </div>
              </div>
            </Frame>
            {/*renderForm()*/}
            <Frame>
              <Form
                initialPayload={{
                  oldPassword: "",
                  newPassword: "",
                  repeatPassword: "",
                }}
                options={editPasswordOptions}
                onSubmit={payload => updatePassword(payload)}
                submitting={updatingPassword}
                apiErrorMessage={updatePasswordError}
                apiSuccessMessage={updatePasswordSuccess}
                reducedPadding={true}
              />
            </Frame>
          </div>
        )}
      </div>
    </PageWrapper>
  )
}
