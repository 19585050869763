import React, { useState, useRef, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import CreateIcon from "@material-ui/icons/Create"
import PageWrapper from "../../../components/PageWrapper"

import {
  GlobalDispatchContext,
} from "../../../context/GlobalContextProvider"

import QuoteSummary from "../quote/containers/quoteCreation/QuoteSummary"
import { TenancySummaryContainer } from "./containers"
import TenantsSummaryContainer from "./containers/TenantsSummaryContainer"

import { Button, Center, TextWithSubtext } from "../components"

import { useGetGroup, useGetPaid, useSetSignupLater } from "../services/group"
import { getMatchingQuotingToolVersionById } from "../services/utils"

import Wrapper from "../components/Wrapper"

import TrustpilotFooter from "../components/TrustpilotFooter"

const useStyles = makeStyles(() => ({
  fixedSignupButtonWrapper: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#82D0FF",
    zIndex: 10000,
  },
  fixedSignupButton: {
    maxWidth: 600,
    width: "100%",
  },
}))

export default function SignUpPage({ location, toolId, groupId, siteData }) {
  const dispatch = useContext(GlobalDispatchContext)

  const classes = useStyles()

  const tenantSignupSectionRef = useRef()

  const frontEndUrl = window.location.origin

  const groupQuery = useGetGroup(groupId)
  const paidQuery = useGetPaid(groupId)
  const postSignupLater = useSetSignupLater()

  const [showFixedSignUp, setShowFixedSignUp] = useState(true)

  const listenToScroll = () => {
    const heightToHide = tenantSignupSectionRef?.current?.getBoundingClientRect()
      .top
    const windowScrollHeight = window.innerHeight
    if (windowScrollHeight > heightToHide) {
      setShowFixedSignUp(false)
    } else {
      setShowFixedSignUp(true)
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", listenToScroll)
    return () => window.removeEventListener("scroll", listenToScroll)
  }, [])

  React.useEffect(() => {
    document.title = "Bunch - Sign Up"
    window.location.hash = "SignUp-Step-01-Overview"
  })

  console.log(siteData)

  let matchingQuotingToolVersion =
    groupQuery.data &&
    groupQuery.data.value &&
    getMatchingQuotingToolVersionById(
      siteData,
      groupQuery.data.value.group.quote.quotingToolVersionId
    )

  const isHomeownerOrRenter = matchingQuotingToolVersion &&
    (matchingQuotingToolVersion.type.includes("Homeowner") ||
      matchingQuotingToolVersion.type.includes("Renter") ||
      matchingQuotingToolVersion.type.includes("Residential"))

  useEffect(() => {
    if (isHomeownerOrRenter) {
      dispatch({ type: "setPriceModeView", priceModeView: "month" })
    }
  }, [isHomeownerOrRenter])

  useEffect(() => {
    if (matchingQuotingToolVersion) {
      var head = document.head
      var link = document.createElement("link")

      link.type = "text/css"
      link.rel = "stylesheet"
      link.href = matchingQuotingToolVersion.customCSSURL

      head.appendChild(link)

      return () => {
        head.removeChild(link)
      }
    }
  }, [matchingQuotingToolVersion])

  if (
    !matchingQuotingToolVersion ||
    groupQuery.isLoading ||
    paidQuery.isLoading
  ) {
    return (
      <PageWrapper
        location={location}
        quoteViewMode={true}
        backgroundColor="#FAFAFA"
        hideHeaderBorder={true}
      >
        <Center>
          <CircularProgress />
        </Center>
      </PageWrapper>
    )
  }

  if (groupQuery.errors || !groupQuery.data.isSuccess) {
    return (
      <PageWrapper
        location={location}
        quoteViewMode={true}
        backgroundColor="#FAFAFA"
        hideHeaderBorder={true}
        topBannerBackgroundColor={
          matchingQuotingToolVersion.topBarBackgroundHexCode
        }
        logoImageUrl={matchingQuotingToolVersion.logoImageUrl}
      >
        Unable to load group.
      </PageWrapper>
    )
  }
  const group = groupQuery.data.value.group
  const { primaryLead, otherLeads, quote } = groupQuery.data.value.group
  const numMissingTenants = Math.max(
    0,
    quote.numberOfTenants - otherLeads.length - 1
  )
  const paid =
    paidQuery.data && paidQuery.data.value
      ? paidQuery.data.value.customerList
      : [""]

  let leadPaid = false
  for (var i = 0; i < paid.length; i++) {
    if (
      paid[i].customerId === primaryLead.customerID &&
      paid[i].hasPayed === true
    ) {
      leadPaid = true
    }
  }

  const address = {
    addressLine1: quote.addressLine1,
    city: quote.city,
    postcode: quote.postCode,
    country: quote.country,
  }

  const setSignupLater = () => {
    postSignupLater({
      groupId,
    })
  }

  const goToEditQuotePackages = () => {
    window.location.href =
      window.location.origin +
      "/tenants/app/" +
      (toolId || "tool-a") +
      "/signup/" +
      group.quoteId +
      "#Quote-Step-0" +
      matchingQuotingToolVersion.quotingToolPages.length
  }

  const goToEditQuoteDetails = () => {
    window.location.href =
      window.location.origin +
      "/tenants/app/" +
      (toolId || "tool-a") +
      "/signup/" +
      group.quoteId +
      "#Quote-Step-01"
  }

  return (
    <Wrapper
      location={location}
      matchingQuotingToolVersion={matchingQuotingToolVersion}
      group={group}
      toolId={toolId}
      quoteId={group.quote.id}
      quoteSummaryData={{
        services: groupQuery.data.value.services.services,
        tenancyDetails: group.quote.tenancyDetails,
        packageDetails: group.quote.packageDetails,
      }}
      isHomeownerOrRenter={isHomeownerOrRenter}
    >
      {showFixedSignUp &&
        group &&
        !leadPaid &&
        !group.primaryLead.isPartnerPrimaryLead && (
          <div className={classes.fixedSignupButtonWrapper}>
            <div className={classes.fixedSignupButton}>
              <Button
                fullWidth={true}
                onClick={() =>
                  navigate(
                    "/tenants/app/signup/complete/" + groupId + "/" + primaryLead.id
                  )
                }
                showShine
                className="quotingTool-submitButton"
              >
                Sign up
              </Button>
            </div>
          </div>
        )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 300px)",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            minWidth: "320px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100%", maxWidth: "600px" }}>
            <div style={{ marginTop: 40, width: "100%" }}>
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  width: "100%",
                  padding: 20,
                }}
              >
                <TextWithSubtext
                  text={matchingQuotingToolVersion.signupQuoteTitle}
                  subtext={matchingQuotingToolVersion.signupQuoteDescription}
                  popupText={matchingQuotingToolVersion.signupQuoteInfoText}
                />
                {!leadPaid ? (
                  <div
                    onClick={() => goToEditQuotePackages()}
                    onKeyPress={() => goToEditQuotePackages()}
                    role="button"
                    tabIndex="0"
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      height: "20px",
                      width: "20px",
                      padding: "20px",
                      backgroundColor: "white",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      marginLeft: 10,
                    }}
                  >
                    <CreateIcon fontSize="small" />
                  </div>
                ) : null}
              </div>
            </div>

            {(leadPaid || group.primaryLead.isPartnerPrimaryLead) && (
              <div style={{ padding: 20 }}>
                <div style={{ marginBottom: "20px" }}>
                  <TextWithSubtext
                    text={matchingQuotingToolVersion.signupTenantsTitle}
                    subtext={
                      matchingQuotingToolVersion.signupTenantsDescription
                    }
                    popupText={matchingQuotingToolVersion.signupTenantsInfoText}
                  />
                </div>
                <div
                  ref={tenantSignupSectionRef}
                  style={{ marginBottom: "30px", width: "100%" }}
                >
                  <TenantsSummaryContainer
                    leadPaid={leadPaid}
                    isPartnerPrimaryLead={
                      group.primaryLead.isPartnerPrimaryLead
                    }
                    primaryLead={primaryLead}
                    otherleads={otherLeads}
                    frontEndUrl={frontEndUrl}
                    groupId={groupId}
                    numMissingTenants={numMissingTenants}
                    paid={paid}
                  />
                </div>
              </div>
            )}

            {/* <FormLabels
              label={matchingQuotingToolVersion.signupSignupLaterTitle}
              description={
                matchingQuotingToolVersion.signupSignupLaterDescription
              }
              popupText={matchingQuotingToolVersion.signupSignupLaterInfoText}
            /> */}
            {!leadPaid && !group.primaryLead.isPartnerPrimaryLead && (
              <div ref={tenantSignupSectionRef}>
                <Button
                  fullWidth={true}
                  onClick={() =>
                    navigate(
                      "/tenants/app/signup/complete/" + groupId + "/" + primaryLead.id
                    )
                  }
                  showShine
                  className="quotingTool-submitButton"
                  style={{ marginBottom: 20 }}
                >
                  Sign up
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <TrustpilotFooter />
    </Wrapper>
  )
}
