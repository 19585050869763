import React, { useContext } from 'react';
import { Link, navigate } from "gatsby";
import { Box, Paper, Typography, Button, Container, Divider } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import SpeedIcon from '@mui/icons-material/Speed';
import PaymentIcon from '@mui/icons-material/Payment';
import LockIcon from '@mui/icons-material/Lock';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { GlobalStateContext } from "../../../context/GlobalContextProvider";

// Custom theme with brand colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#004452', // Deep teal
    },
    secondary: {
      main: '#8FF2D1', // Mint green
    },
    accent: {
      main: '#F45789', // Coral/salmon
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F5F5F5',
    },
  },
});

const MaintenanceAlert = ({ tenantId }) => {
  const context = useContext(GlobalStateContext);

  // Get tenantId from context if not provided directly
  const currentTenantId = tenantId || (context?.tenantUser?.tenancies?.[0]?.id);

  // Navigation handlers
  const handleMeterReadingsClick = () => {
    navigate(`/tenants/app/tenancies/${currentTenantId}/meters`);
  };

  const handlePaymentDetailsClick = () => {
    navigate(`/tenants/app/tenancies/${currentTenantId}/bank-details`);
  };

  const handlePasswordClick = () => {
    navigate(`/tenants/app/tenancies/${currentTenantId}/account-details`);
  };

  const handleContactClick = () => {
    window.open("https://www.the-bunch.co.uk/contact-us", "_blank");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ my: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 2,
            background: 'linear-gradient(to right, #FFFFFF, #F5F5F5)',
            position: 'relative',
            overflow: 'hidden',
            borderTop: '5px solid #004452',
          }}
        >
          {/* Decorative elements */}
          <Box
            sx={{
              position: 'absolute',
              top: -20,
              right: -20,
              width: 120,
              height: 120,
              borderRadius: '50%',
              bgcolor: 'secondary.light',
              opacity: 0.3
            }}
          />

          {/* Header */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <ConstructionIcon sx={{ fontSize: 40, color: '#004452', mr: 2 }} />
            <Typography variant="h4" component="h1" color="primary.main" fontWeight="bold">
              🚨 Major Changes Coming Soon! 🚨
            </Typography>
          </Box>

          {/* Main message */}
          <Typography variant="h6" sx={{ mb: 3, color: 'text.secondary' }}>
            We are rolling out updates to improve your experience. Stay tuned!
          </Typography>

          <Divider sx={{ my: 3, bgcolor: 'rgba(0, 68, 82, 0.2)' }} />

          {/* Available services */}
          <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 2, color: 'primary.main' }}>
            In the meantime, you can still access these services:
          </Typography>

          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 4 }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SpeedIcon />}
              onClick={handleMeterReadingsClick}
              sx={{
                borderRadius: 4,
                '&:hover': {
                  bgcolor: 'secondary.main',
                  borderColor: 'secondary.main',
                }
              }}
            >
              Add Meter Readings
            </Button>

            <Button
              variant="outlined"
              color="primary"
              startIcon={<PaymentIcon />}
              onClick={handlePaymentDetailsClick}
              sx={{
                borderRadius: 4,
                '&:hover': {
                  bgcolor: 'secondary.main',
                  borderColor: 'secondary.main',
                }
              }}
            >
              Update Payment Details
            </Button>

            <Button
              variant="outlined"
              color="primary"
              startIcon={<LockIcon />}
              onClick={handlePasswordClick}
              sx={{
                borderRadius: 4,
                '&:hover': {
                  bgcolor: 'secondary.main',
                  borderColor: 'secondary.main',
                }
              }}
            >
              Change Password
            </Button>
          </Box>

          {/* Support information */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'primary.main',
              p: 2,
              borderRadius: 2,
              color: 'white'
            }}
          >
            <SupportAgentIcon sx={{ fontSize: 30, mr: 2 }} />
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">
                Need further assistance?
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.9 }}>
                Please contact our Customer Services team
              </Typography>
            </Box>
            <Button
              onClick={handleContactClick}
              sx={{
                ml: 'auto',
                borderRadius: 4,
                bgcolor: '#F45789',
                '&:hover': {
                  bgcolor: '#ff6e4e',
                },
                boxShadow: 2
              }}
              variant="contained"
            >
              Contact Us
            </Button>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default MaintenanceAlert;